.upload-image > td:first{
    border: 2px solid red;
}

.empty-customers{
    text-align: center;
    font-weight: 600;
}

.pending-appication-table th, .pending-appication-table td{
    border:none;
}
.form-control{
    height: 3rem;
}


#customer-form{
    margin: 10px 2vw;
}
#customer-form .form-control{
    margin-bottom: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: .5;
}

.modal{
  top:10%;
}

#agent-table{
  margin-left: 20px;
}

#agent-table table th{
  padding: 5px;
}

#agent-table table tr{
  border-bottom: 1px solid rgb(189, 189, 189) !important;
}

#agent-table table th{
  padding-top: 10px;
}




.dropbtn:hover, .dropbtn:focus {
  background-color: rgb(78, 6, 18);
}


#myInput {
  box-sizing: border-box;
  /* background-image: url('searchicon.png'); */
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
}


#myInput:focus {outline: 3px solid #ddd;}


.dropdown {
  position: relative;
  display: inline-block;
}

#myDropdownmenu{
  max-height: 50vh;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}


.dropdown-content option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}


.dropdown a:hover {background-color: #ddd;}


.show {display: block;}

.product-per-page{
  width: auto;
  display: inline-block;
  float: right;
}

.show-password{
  cursor:pointer;
  float: right;
  position: relative;
  margin-top: -35px;
  margin-right: 15px;
  font-size:20px;
}

.remove-image-icon{
  position: absolute;
  right: 12px;
  top: 1%;
  background-color: white;
  padding: 5px;
  width: 25px;
  border-radius: 50%;
  z-index: 9;
  height: 25px;
  text-align: center;
  border: 1px solid #888;
}

.remove-image-icon:hover{
  /* opacity: 0.5; */
  font-size: 18px;
  cursor: pointer;
}

.remove-image-icon > i{
  color: red !important;
  height: 100%;
  vertical-align: top;
}

.capture-image-wrapper{
  max-width: fit-content;
  width: 150px !important;
}

.capture-image-wrapper > span{
  right: 9%;
  top: -2%;
} 

.a-disabled{
  cursor: not-allowed;
}

@media (min-width: 992px){
.col-lg-3 {
    flex: 0 0 25%;
    max-width: 100%;
}

}

.required_red{
  color: red;
}