#mobile-menu{
    display: none;
}
@media only screen and (max-width: 320px) {
    
    .sidebar-brand-text{
        font-size: 14px;
    }
    .pending-appication-table{
        width: 100% !important;
    }
}
@media (min-width:576px) and (max-width: 767px) {
    .topbar .navbar-search {
        width: 19rem;
    }
    .pending-appication-table{
        width: 100% !important;
    }
}
@media screen and (max-width: 767px) {
    #accordionSidebar{
        display: none !important;
    }
    #mobile-menu{
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 100;
    }
    .mobile-card div{
        margin-bottom: 5px !important;
    }
    .fa-laugh-wink{
        font-size: 30px !important;
    }
    .fa-bars{
        font-size: 20px !important;
    }
    .mb-sm-2{
        margin-bottom: 8px;
    }
    .pending-appication-table{
        width: 100% !important;
    }
    .btns-mobile-size > button{
        margin-bottom: 10px;
    }
}
@media (min-width: 767px) and (max-width:992px){
    .mobile-logo{
        display: none !important;
    }
    .mobile-card .col-md-3{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .pending-appication-table{
        width: 100% !important;
    }
}
